<kendo-dialog
  title="{{ 'navigationTopBar.customerService' | translate }}"
  (close)="close()"
  [maxWidth]="1320"
  maxHeight="90vh"
>
  <ntw-loading-panel *ngIf="loading" [fullscreen]="false" themeColor="primary"></ntw-loading-panel>
  <ng-container *ngIf="!loading">
    <div *ngIf="this.isAlcroCustomer()" class="text-start">
      <h3>Kontaktinformation Kundcenter</h3>
      E-postadress: <a draggable="false" href="mailto:kundcenter@alcro.se">kundcenter&#64;alcro.se</a>
      <br />
      Telefonnummer: <a draggable="false" href="tel:08-7756227">08-775 62 27</a>
      <br />
      Öppettider: mån-ons 08:00-16:30, tor-fre 08:00-15:45
      <br />
      <br />
      <br />
      <strong>Frakt- och leveransvillkor</strong>
      <br />
      Order levereras på körtur.
      <br />
      För leveranser utanför körtur (extrabil) kontakta Kundcenter.
    </div>
    <div *ngIf="this.isBeckersCustomer()" class="text-start">
      <h3>Kontaktinformation Kundcenter</h3>
      E-postadress: <a draggable="false" href="mailto:kundcenter@beckers.se">kundcenter&#64;beckers.se</a>
      <br />
      Telefonnummer: <a draggable="false" href="tel:08-7756109">08-775 61 09</a>
      <br />
      Öppettider: mån-ons 08:00-16:30, tor-fre 08:00-15:45
      <br />
      <br />
      <br />
      <strong>Frakt- och leveransvillkor</strong>
      <br />
      Order levereras på körtur.
      <br />
      För leveranser utanför körtur (extrabil) kontakta Kundcenter.
    </div>
  </ng-container>
  <kendo-dialog-actions>
    <button kendoButton (click)="close()">{{ 'shared.close' | translate}}</button>
  </kendo-dialog-actions>
</kendo-dialog>
